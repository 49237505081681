<template lang="pug">
v-data-table.elevation-1(
  :loading="loading",
  :headers="headers",
  :items="items",
  :multi-sort="true",
  @update:options="changeParams($event)",
  no-data-text="No se ha encontrado usuarios"
)

  template(v-slot:item.detail="{ item }")
    v-icon.mr-2(small, @click="$emit('detalle' ,item)")
      | fa-external-link-alt

  template(v-slot:item.delete="{ item }")
    v-icon.mr-2(
      small,
      @click="$emit('delete',item.id)"
    )
      | fa-trash-alt
</template>
<script>
import userDetail from './Detail'
import Form from './Form'
import addHeaders from '@/layouts/templates/Tables/tableHeaders'
import operationsMixin from '#/Lists/operations/clientSideCrud'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: '',
  components: {
    userDetail
    // Form
  },
  mixins: [operationsMixin],
  computed: {
    headers () {
      return addHeaders(this.table_headers, false)
    },
    ...mapGetters({
      items: 'users/list'
    })
  },
  methods: {
    async getData () {
      this.loading = true
      await this.$store.dispatch('users/FETCH_USERS')
      this.loading = false
    },
    changeParams (options) {
      this.$set(this.params, 'page', options.page)
      let size =
        options.itemsPerPage > 0 ? options.itemsPerPage : this.totalItems
      this.$set(this.params, 'page_size', size)
      let orders = ''
      for (var i = 0; i < options.sortBy.length; i++) {
        if (i > 0) orders += ','
        orders += options.sortDesc[i] ? '-' : ''
        let field = options.sortBy[i].includes('.')
          ? options.sortBy[i].replace('.', '__')
          : options.sortBy[i]
        orders += field
      }
      this.$set(this.params, 'ordering', orders)
    }
  },
  mounted () {
    this.getData()
    window.firestore = this.$firestore
  },
  data () {
    return {
      selected_period: '',
      modelName: 'accounts',
      modelNamePlural: 'Usuarios',
      downloading: false,
      searchField: true,
      loading: false,
      params: {},
      table_headers: [
        { text: 'Nombre de Usuario', value: 'username' },
        { text: 'Nombre', value: 'first_name' },
        { text: 'Apellidos', value: 'last_name' },
        // { text: 'Detalle', sortable: false, value: 'detail' },
        { text: 'Eliminar', sortable: false, value: 'delete' }
      ]
      //
      // service: this.$django.models.,
    }
  }
}
</script>
<style>
/* .v-data-table-header{
    background-color: rgb(87, 0, 0);
  }
  thead.text-start {
  }
  th {
    color:white !important;
    font-size: 17px !important;
  } */
</style>
