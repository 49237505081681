<template lang="pug">
v-card
  ModalEdit(
    ref="ModalEdit",
    @successChange="setUser($event)"
    :editInstance="user"
    :action="$firestore.models.users.partialUpdate"
  )
  PasswordForm(ref="PasswordForm")
  .container(v-if="user")
    .row.justify-center.secondary.white--text
      .col.text-center(md="3" v-editable @edit="EditField('username')")
          p.headline.font-weight-bold
            | {{ user.username }}
    v-row.justify-center
      v-col.text-center(v-editable @edit="EditField('first_name', 'nombre')")
        h4.overline Nombres
          p.headline
            | {{user.first_name | nonempty }}
      v-col.text-center(v-editable @edit="EditField('last_name','apellido')")
        h4.overline Apellidos
          p.headline
            | {{user.last_name | nonempty }}
            //- EditableElement(")
    v-row
      v-col.text-center( v-editable @edit="EditField('email','email','email')")
          h4
            v-icon.mx-2 fa-at
            | {{user.email | nonempty }}
      v-col.text-center
        v-btn.secondary(@click="$refs.PasswordForm.edit(user.id, {id:user.id})") Cambiar Contraseña
</template>
<script>
import PasswordForm from './PasswordForm'
export default {
  name: 'UserCard',
  props: {
    userInstance: {
      default: null
    }
  },
  data () {
    return {

    }
  },
  components: {
    PasswordForm
  },
  methods: {
    EditField (fieldname, name = null, type = 'text') {
      if (!name) name = fieldname
      this.$refs.ModalEdit.open(fieldname, type, name)
    },
    setUser (user) {
      console.log('cambio')
      console.log(user)
      this.$emit('cambio', user)
    }
  },
  computed: {
    user () {
      // console.log(this.$store.state)
      if (!this.userInstance) return this.$store.state.accounts.user
      else return this.userInstance
    },
    id () {
      return this.user.id
    },
    action () {
      return this.$firestore.models.users.partial_update
    }
  },
  filters: {
    nonempty (val) {
      if (val === '') return 'not defined'
      return val
    }
  }
}
</script>
