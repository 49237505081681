<template lang="pug">
.container.mb-5.fluid.fill-height
  DeleteConfirmation(
    model="users",
    ref="DeleteConfirmation",
    @success="$refs.UserTable.removeItem($event)"
  )
  UserDetail(ref="userDetail" @successChange="$refs.UserTable.getData()",)
  UserForm(ref="UserForm"
    @created="$refs.UserTable.getData()"
  )
  .row.justify-end.mr-3
    v-btn.mr-2(@click="$router.push({ name: 'main' })") Dinamicas
    v-btn.black--text(color="primary" light @click="$refs.UserForm.open()") Agregar
  .row.justify-center
    .col
      UserTable(
        ref="UserTable"
        @detalle="$refs.userDetail.view($event.id, $event)"
        @delete="$refs.DeleteConfirmation.open($event, 'Usuario')"
      )

</template>
<script>
import {
  Detail as UserDetail,
  Form as UserForm,
  Info as UserInfo,
  Table as UserTable,
  PasswordForm,
  LogInfo
} from '@/components/users'

export default {
  name: 'Users',
  components: {
    UserDetail,
    UserForm,
    UserInfo,
    UserTable,
    PasswordForm,
    LogInfo
  }
}
</script>
