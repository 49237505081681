<template lang="pug">
extends ../../layouts/templates/Modal/ModalForm
block content
  .container
    .row
      .col
        v-text-field(label="Nueva contraseña",
        type="password"
        v-model="form.password", :error-messages="errors.password", :rules="[valrules.required]")
    .row
      .col
        v-text-field(label="Confirmar contraseña",
        type="password", v-model="form.passwordconf", :error-messages="errors.passwordconf", :rules="[valrules.required]")
</template>
<script>
import { formModal } from '@/mixins/Modal'
export default {
  mixins: [ formModal ],
  data () {
    return {
      activator: false,
      maxWidth: '600',
      customFormTitle: 'Cambiar Contraseña',
      modalColor: 'terciary',
      modalDark: true,
      model_name: 'Contraseña',
      editAction: 'editPassword',
      model: this.$firestore.models.users,
      form: {
        password: '',
        passwordconf: ''
      }
    }
  }
}
</script>
