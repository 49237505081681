<template lang="pug">
extends ../../layouts/templates/Modal/ModalForm
append content
  v-container.pa-5
    v-row
      v-col
        v-text-field(label="Nombres", v-model="form.first_name", :error-messages="errors.first_name", :rules="[valrules.required]")
      v-col
        v-text-field(label="Apellidos", v-model="form.last_name", :error-messages="errors.last_name", :rules="[valrules.required]")
    v-row
      v-col
        v-text-field(label="Nombre de Usuario", v-model="form.username", :rules="[valrules.required]", :error-messages="errors.username")

    v-row
      v-col
        v-text-field(label="Email", :rules="[valrules.required]", prepend-icon='fa-at', v-model="form.email", :error-messages="errors.email")
    v-row
      v-col
        v-text-field(label="Nueva contraseña",
        type="password"
        v-model="form.password", :error-messages="errors.password", :rules="[valrules.required]")
      v-col
        v-text-field(label="Confirmar contraseña",
        type="password", v-model="form.passwordconf", :error-messages="errors.passwordconf", :rules="[valrules.required]")

    v-row(v-if="fullmode")
      v-col
        v-text-field(label="Telefono", prepend-icon='phone', :rules="[valrules.required]", v-model="form.phone", :error-messages="errors.phone")

//- block title
//-   v-toolbar-title
//-     span.headline  Nuevo Usuario
//-     v-icon fa-times

</template>
<script>
import { formModal } from '@/mixins/Modal'
import * as yup from 'yup'

export default {
  props: {
    fullmode: { default: false }
  },
  mixins: [ formModal ],
  methods: {
    // sendForm() {
    // if (this._validateForm()) {
    //     this.$emit('added',this.form)
    //     this.exito()
    //   }
    // },
  },
  watch: {
    // form: {
    //   deep: true,
    //   handler: function (form) {
    //     let val = {}
    //     const self = this
    //     let schema = yup.object().shape({
    //       first_name: yup.string().required(),
    //       last_name: yup.string().required(),
    //       username: yup.string().required(),
    //       email: yup.string().email(),
    //       password: yup.number().required(),
    //       passwordconf: yup.number().required()
    //     })
    //     schema.validate(form).then(succes => {
    //       console.log('succes')
    //       console.log(succes)
    //     }).catch(err => {
    //       console.log(err)
    //       console.log(this)
    //       self.validity['errors'] = err
    //     })
    //   }
    // }
  },
  computed: {
    formTitle () { return 'Add new' }
  },
  data () {
    return {
      activator: false,
      maxWidth: null,
      customFormTitle: 'Nuevo Usuario',
      modalColor: 'terciary',
      modalDark: true,
      model_name: 'Usuario',
      buttomText: 'Nuevo Usuario',
      model: this.$firestore.models.users,
      validity: {},
      form: {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        passwordconf: '',
        is_staff: true,
        coso: 'rico'
      }
    }
  }
}
</script>
